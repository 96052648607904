// Ensure that keys are sorted, to reduce merge conflicts
/* eslint sort-keys: "error" */
type PrimitiveSupportedGateTypes = boolean | number | string;
export type SupportedGateTypes =
  | Array<PrimitiveSupportedGateTypes>
  | PrimitiveSupportedGateTypes
  | {
      [key: string]: PrimitiveSupportedGateTypes;
    };

/**
 * Feature gates / experiments in Statsig have to be defined here as well.
 * The `owner` field should be your Trello username.
 */
export const featureGates = {
  'ah-trello-web-shadow-it-controls': {
    createdOn: '2024-08-19',
    owner: 'soluwaleimu',
  },
  billplat_ccp_create_workspace: {
    createdOn: '2024-07-09',
    owner: 'jborczuk',
  },
  billplat_fg_use_free_to_add_query: {
    createdOn: '2024-07-31',
    owner: 'jmatthews',
  },
  billplat_modernize_workspace_boards_page: {
    createdOn: '2024-06-24',
    owner: 'mpinnell',
  },
  billplat_modernize_workspace_exports_page: {
    createdOn: '2024-06-18',
    owner: 'aterkik',
  },
  billplat_modernize_workspace_powerups_page: {
    createdOn: '2024-06-20',
    owner: 'tsiddiqui',
  },
  billplat_modernize_workspace_settings_page: {
    createdOn: '2024-07-08',
    owner: 'pperry',
  },
  editor_plugin_dropbox: {
    createdOn: '2024-05-21',
    owner: 'mellis',
  },
  editor_plugin_googledrive: {
    createdOn: '2024-05-21',
    owner: 'mellis',
  },
  ks_smart_lists: {
    createdOn: '2024-08-29',
    owner: 'bessary',
  },
  phoenix_fg_split_screen_nav: {
    createdOn: '2024-08-02',
    owner: 'pperry',
  },
  phoenix_open_cardback_anywhere: {
    createdOn: '2024-08-12',
    owner: 'ajaiman',
  },
  phx_board_menu_modernization: {
    createdOn: '2024-08-09',
    owner: 'hburinda',
  },
  phx_board_switcher: {
    createdOn: '2024-09-05',
    owner: 'mellis',
  },
  phx_griffin_header: {
    createdOn: '2024-08-28',
    owner: 'pperry',
  },
  phx_link_card_conversion: {
    createdOn: '2024-09-03',
    owner: 'mellis',
  },
  'post-office_enable_trello_placement': {
    createdOn: '2024-07-01',
    owner: 'sliaw',
  },
  tplat_fg_migrate_boardselectorganizationview: {
    createdOn: '2024-08-22',
    owner: 'koduche',
  },
  tplat_fg_migrate_changeboardbackgroundselectview: {
    createdOn: '2024-08-21',
    owner: 'koduche',
  },
  tplat_fg_migrate_createfirstboardview: {
    createdOn: '2024-08-08',
    owner: 'koduche',
  },
  tplat_fg_migrate_dialog: {
    createdOn: '2024-08-01',
    owner: 'koduche',
  },
  tplat_fg_migrate_renderapp: {
    createdOn: '2024-08-22',
    owner: 'koduche',
  },
  tplat_fg_migrate_teambannerview: {
    createdOn: '2024-08-01',
    owner: 'koduche',
  },
  tplat_fg_migrate_usetablerowdraganddrop: {
    createdOn: '2024-08-09',
    owner: 'koduche',
  },
  tplat_new_date_parsing: {
    createdOn: '2024-08-09',
    owner: 'mfaith',
  },
  tplat_new_format_relative: {
    createdOn: '2024-08-15',
    owner: 'mfaith',
  },
  tplat_new_time_parsing: {
    createdOn: '2024-08-09',
    owner: 'mfaith',
  },
  tplat_remove_global_styles_forms_less: {
    createdOn: '2024-09-05',
    owner: 'mfoulks',
  },
  'trello-server-e2b-ai': {
    createdOn: '2024-05-14',
    owner: 'brianzawisza1',
  },
  'trello-web-seat-automation-member-blocklist': {
    createdOn: '2024-08-08',
    owner: 'alitskevitch',
  },
  trello_enterprise_reverse_trial_aa_observation: {
    createdOn: '2024-06-19',
    owner: 'davidtbernal',
  },
  trello_enterprise_reverse_trial_experience: {
    createdOn: '2024-05-31',
    owner: 'davidtbernal',
  },
  trello_inbox_email_preference_visible: {
    createdOn: '2024-09-12',
    owner: 'rryumae',
  },
  trello_native_graphql_migration_milestone_1: {
    createdOn: '2024-07-12',
    owner: 'vkozachok',
  },
  trello_new_time_picker: {
    createdOn: '2024-07-15',
    owner: 'mfaith',
  },
  trello_nlp_date_parsing: {
    createdOn: '2024-06-25',
    owner: 'egorobets',
  },
  'trello_non-ppm_single_player': {
    createdOn: '2024-01-22',
    owner: 'achung',
  },
  trello_paid_workspaces: {
    createdOn: '2024-03-01',
    owner: 'wloo2',
  },
  trello_plat_migrate_directorylistingview: {
    createdOn: '2024-07-30',
    owner: 'koduche',
  },
  trello_plat_migrate_directoryview: {
    createdOn: '2024-07-22',
    owner: 'koduche',
  },
  trello_plat_migrate_memberhomeview: {
    createdOn: '2024-08-01',
    owner: 'koduche',
  },
  trello_plat_migrate_organizationboardsview: {
    createdOn: '2024-07-31',
    owner: 'koduche',
  },
  trello_xf_discovery_ads_control: {
    createdOn: '2024-08-02',
    owner: 'achung',
  },
  trello_xf_experiment_analytics_toggle: {
    createdOn: '2024-03-11',
    owner: 'achung',
  },
  trello_xf_invite_experience_2: {
    createdOn: '2024-05-14',
    owner: 'mleaf',
  },
  trello_xf_mark_card_as_viewed: {
    createdOn: '2024-07-09',
    owner: 'ccurtis',
  },
  trello_xf_new_user_workspace_linking: {
    createdOn: '2024-07-30',
    owner: 'mpittman',
  },
  trello_xf_no_retry_on_new_card_comments: {
    createdOn: '2024-08-09',
    owner: 'jnierendorf',
  },
  trello_xf_one_liner_header_experience: {
    createdOn: '2024-05-10',
    owner: 'mshih',
  },
  trello_xf_use_card_drag_external: {
    createdOn: '2024-05-08',
    owner: 'jsaussy',
  },
  trello_xf_use_pdnd_external_adapter: {
    createdOn: '2024-04-23',
    owner: 'jsaussy',
  },
  trello_xf_use_server_list_sorting: {
    createdOn: '2024-06-05',
    owner: 'ccurtis',
  },
  trello_xf_use_view_for_calendar_powerup: {
    createdOn: '2024-04-23',
    owner: 'jsaussy',
  },
  xf_card_back_modernization: {
    createdOn: '2024-04-18',
    owner: 'rwang3',
  },
  xf_card_composer_redesign: {
    createdOn: '2024-06-05',
    owner: 'rwang3',
  },
  xf_fg_planner: {
    createdOn: '2024-08-29',
    owner: 'rwang3',
  },
  xf_inbox_spike: {
    createdOn: '2024-06-03',
    owner: 'afecenko',
  },
  xf_list_sort_modernization: {
    createdOn: '2024-07-15',
    owner: 'julietzhang',
  },
  xf_mirror_cards: {
    createdOn: '2024-07-24',
    owner: 'slondon',
  },
  xf_smart_lists: {
    createdOn: '2024-07-29',
    owner: 'mleaf',
  },
} as const;

export const featureExperiments = {
  trello_collapse_list_upsell_upgrade_multivariate: {
    createdOn: '2024-08-01',
    owner: 'kbarrett',
    parameters: {
      cohort: ['variantA', 'variantB', 'variantC'],
    },
  },
  trello_enterprise_reverse_trials: {
    createdOn: '2024-05-09',
    owner: 'mkalil',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  trello_enterprise_reverse_trials_aa: {
    createdOn: '2024-06-19',
    owner: 'dbernal',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  trello_enterprise_reverse_trials_m2_aa: {
    createdOn: '2024-06-19',
    owner: 'dbernal',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  trello_enterprise_trial_ended_friction: {
    createdOn: '2024-07-16',
    owner: 'jlei',
    parameters: {
      cohort: ['experiment', 'control'],
      // eslint-disable-next-line @trello/no-module-logic
      experimentStartDate: [new Date('2024-08-14T11:30:30').toISOString()],
    },
  },
  trello_phoenix_collapse_list_free_trial_baseline: {
    createdOn: '2024-08-08',
    owner: 'kbarrett',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  trello_phoenix_collapse_list_free_trial_multi: {
    createdOn: '2024-08-20',
    owner: 'kbarrett',
    parameters: {
      cohort: ['variantA', 'variantB', 'variantC'],
    },
  },
  trello_phoenix_collapse_list_upgrade_baseline: {
    createdOn: '2024-07-22',
    owner: 'kbarrett',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  trello_xf_1p_ads_in_madshot_v2: {
    createdOn: '2023-03-28',
    owner: 'mshih',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  trello_xf_cta_and_funnel_new_user_in_product_nudge: {
    createdOn: '2023-05-30',
    owner: 'mshih',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  trello_xf_explanatory_use_case_selection: {
    createdOn: '2023-05-01',
    owner: 'mshih',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  trello_xf_new_users_combinations: {
    createdOn: '2024-06-21',
    owner: 'mshih',
    parameters: {
      cohort: ['control', 'variant-1', 'variant-2', 'variant-3'],
    },
  },
  'trello_xf_non-ppm_to_ppm_': {
    createdOn: '2024-08-29',
    owner: 'wloo2',
    parameters: {
      cohort: ['control', 'variant-1'],
    },
  },
  trello_xf_paid_users_round_3: {
    createdOn: '2024-06-18',
    owner: 'achung',
    parameters: {
      cohort: ['control', 'experiment'],
    },
  },
  trello_xf_paid_workspaces_ads: {
    createdOn: '2024-03-01',
    owner: 'wloo2',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  trello_xf_site_creation_copy_change: {
    createdOn: '2024-08-02',
    owner: 'achung',
    parameters: {
      cohort: ['control', 'experiment'],
    },
  },
  trello_xf_spork_branding_positioning_free_ppm: {
    createdOn: '2024-03-27',
    owner: 'achung',
    parameters: {
      cohort: ['collaboration', 'customization'],
    },
  },
  trello_xf_spork_branding_positioning_multiplayer: {
    createdOn: '2024-03-27',
    owner: 'achung',
    parameters: {
      cohort: ['collaboration', 'customization'],
    },
  },
  trello_xf_spork_branding_positioning_new_users: {
    createdOn: '2024-03-27',
    owner: 'achung',
    parameters: {
      cohort: ['collaboration', 'customization'],
    },
  },
  trello_xf_spork_branding_positioning_paid_users: {
    createdOn: '2024-03-27',
    owner: 'achung',
    parameters: {
      cohort: ['collaboration', 'customization'],
    },
  },
  trello_xf_spork_branding_positioning_single_player: {
    createdOn: '2024-03-27',
    owner: 'achung',
    parameters: {
      cohort: ['collaboration', 'customization'],
    },
  },
  trello_xf_template_gallery_v1_non_ppm: {
    createdOn: '2024-07-22',
    owner: 'pnguyen',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  trello_xf_template_gallery_v1_ppm: {
    createdOn: '2024-07-18',
    owner: 'pnguyen',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  xf_cffe_for_unverified_non_invited_new_user: {
    createdOn: '2024-09-05',
    owner: 'achung',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  xf_cffe_for_verified_users_in_onboarding: {
    createdOn: '2024-09-09',
    owner: 'achung',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  xf_enterprise_self_serve: {
    createdOn: '2024-09-10',
    owner: 'dvenkatachalam',
    parameters: {
      cohort: ['control', 'experiment'],
    },
  },
  xf_jira_premium_trial_non_ppm_single_player: {
    createdOn: '2024-08-12',
    owner: 'dvenkatachalam',
    parameters: {
      cohort: ['control', 'experiment'],
    },
  },
  xf_jira_premium_trial_paid_users: {
    createdOn: '2024-08-13',
    owner: 'pnguyen',
    parameters: {
      cohort: ['experiment', 'control'],
    },
  },
  xf_new_card_placeholder_change: {
    createdOn: '2024-09-03',
    owner: 'jsaussy',
    parameters: {
      cohort: ['control', 'experiment'],
    },
  },
} as const;

export const featureLayers = {
  trello_web_layers_aa_test: {
    createdOn: '2024-08-07',
    owner: 'achung',
    parameters: {
      cohort: ['experiment', 'control', 'variant-1', 'variant-2'],
    },
  },
} as const;

export type FeatureGateKeys = keyof typeof featureGates;
export type FeatureExperimentKeys = keyof typeof featureExperiments;
export type FeatureLayersKeys = keyof typeof featureLayers;
export type FeatureExperimentParameters<K extends FeatureExperimentKeys> =
  keyof (typeof featureExperiments)[K]['parameters'];
export type FeatureLayerParameters<K extends FeatureLayersKeys> =
  keyof (typeof featureLayers)[K]['parameters'];

export type RegisteredFeatureKey = FeatureExperimentKeys | FeatureGateKeys;

/**
 * Used to narrow down to the experiment variations types, given the experiment key and parameter key
 * e.g. type Variations = ExperimentVariations<'trello_experiment_key', 'cohort'>
 */
export type ExperimentVariations<
  K extends FeatureExperimentKeys,
  P extends FeatureExperimentParameters<K>,
> =
  // Explicit any type is used where we don't actually care about the type. It is used just so we can then
  // index on the variation tuples using [number].
  | 'not-enrolled'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ((typeof featureExperiments)[K]['parameters'] extends Record<P, any>
      ? (typeof featureExperiments)[K]['parameters'][P][number]
      : never);

/**
 * Used to narrow down to the experiment variations types, given the layer key and parameter key
 * e.g. type Variations = ExperimentVariations<'trello_layer_key', 'cohort'>
 */
export type LayerVariations<
  K extends FeatureLayersKeys,
  P extends FeatureLayerParameters<K>,
> =
  // Explicit any type is used where we don't actually care about the type. It is used just so we can then
  // index on the variation tuples using [number].
  | 'not-enrolled'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ((typeof featureLayers)[K]['parameters'] extends Record<P, any>
      ? (typeof featureLayers)[K]['parameters'][P][number]
      : never);
